import React from "react"
import { StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import DropzoneBody from "./dropzone-body"

const propTypes = {
  FilesAction: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  handleDataContainer: PropTypes.object.isRequired,
  callbackFileDuplicateMessage: PropTypes.func,
}
const Dropzone = ({
  FilesAction,
  handleDataContainer,
  limit,
  fileSizeMaxInBytes,
  callbackFileDuplicateMessage,
  handleContentMap,
  uploadType,
}) => (
  <StaticQuery
    query={graphql`
      query {
        cameraLogo: file(relativePath: { eq: "camera.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        documentLogo: file(relativePath: { eq: "curriculum.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <DropzoneBody
        data={data}
        FilesAction={FilesAction}
        dropzoneFiles={handleDataContainer}
        limit={limit}
        maxFileSizeInBytes={fileSizeMaxInBytes}
        callbackFileDuplicateMessage={callbackFileDuplicateMessage}
        handleContentMap={handleContentMap}
        uploadType={uploadType}
      />
    )}
  />
)

Dropzone.propTypes = propTypes

export default Dropzone
