import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import DocumentUploadBody from '../components/document-upload-content/document-upload-body'

const DocumentUpload = () => (
  <Layout>
    <SEO title="Upload Prescription" />
    <DocumentUploadBody />
  </Layout>
)

export default DocumentUpload
