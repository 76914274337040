import React, { useContext } from "react"
import { FilesAction } from "./dropzone-action"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"
import { withTranslation } from "react-i18next"
import ContentFold from "../page-content/content-fold"
import CollapsibleInfo from "../collapsible-info/collapsible-info"
import Dropzone from "../dropzone/dropzone"
import { AppContext } from "../../context/AppContext"

const DocumentUploadBody = ({ t }) => {
  const { state, dispatch } = useContext(AppContext)

  const handleMapDropzoneState = dropzoneFiles => {
    dispatch({ type: "SET_DROPZONE_DETAILS", payload: dropzoneFiles })
  }

  const handleSaveFiles = () => {
    navigate("/cart")
  }

  return (
    <ContentFold title={t("Upload Required Documents")} isCentered>
      <p>
        {t("Tap to take a picture or upload a copy of your prescription.")}{" "}
        {t("You may upload more than one.")}{" "}
        {t("The total price will be confirmed by our pharmacist via SMS.")}{" "}
        {t("You won't be charged anything until you confirm your order.")}
      </p>
      <div className="mt-1 notification is-warning has-text-centered">
        {t(
          "Starting February 2020, MedGrocer will no longer serve pill-split prescriptions (e.g. Sacubitril-Valsartan 200mg tablet, 1/2 tab twice a day). Please visit your doctor to get an updated prescription without pill-splitting."
        )}
      </div>
      <CollapsibleInfo
        title={t("Upload Prescription Guidelines")}
        className="mt-2"
      >
        <ol className="has-text-left">
          <li>{t("Lay the prescription on a flat surface.")}</li>
          <li>
            {t(
              "Take the picture with good lighting. Do not block the light source."
            )}
          </li>
          <li>{t("Make sure that the entire prescription is captured.")}</li>
          <li>
            {t(
              "Move your phone closer and capture the prescription in segments if needed."
            )}
          </li>
          <li>{t("Tap to focus and make the prescription readable.")}</li>
          <li>{t("Connect to WiFi for faster uploading.")}</li>
        </ol>
      </CollapsibleInfo>
      <Dropzone
        limit={5}
        fileSizeMaxInBytes={5242880}
        handleDataContainer={state.filesArray}
        handleContentMap={handleMapDropzoneState}
      />
      <div className="columns is-mobile mt-2 mx-0">
        <div className="column pl-0">
          <Link to="/cart" replace className="button is-fullwidth">
            {t("Back")}
          </Link>
        </div>
        <div className="column pr-0">
          <button
            role="link"
            className="button is-link is-fullwidth"
            onClick={handleSaveFiles}
          >
            {t("Continue")}
          </button>
        </div>
      </div>
    </ContentFold>
  )
}

// class DocumentUploadBody extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       dropzoneStateFiles: this.props.dropzoneFiles,
//     }
//   }

//   handleMapDropzoneState = dropzoneFiles => {
//     this.setState({
//       dropzoneStateFiles: dropzoneFiles,
//     })
//   }

//   handleSaveFiles = () => {
//     this.props.FilesAction(this.state.dropzoneStateFiles)
//     navigate('/cart')
//   }

//   render() {
//     const { t } = this.props
//     return (

//   }
// }

export default withTranslation()(DocumentUploadBody)
