import React, { Component } from "react"
import classNames from "classnames"
import styles from "./collapsible-info.module.scss"

class CollapsibleInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }
  handleClick = event => {
    this.setState(previousState => {
      return { isOpen: !previousState.isOpen }
    })
  }
  render() {
    return (
      <>
        <div
          className={classNames("px-2 py-1", this.props.className)}
          onClick={this.handleClick}
        >
          <div className={classNames(styles["title"], "is-flex")}>
            {this.props.title}
            <svg
              width="20"
              height="13"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"
                fill="#324B5C"
              />
            </svg>
          </div>
          {this.state.isOpen && (
            <div className={classNames(styles["info"], "px-2 py-1")}>
              {this.props.children}
            </div>
          )}
        </div>
      </>
    )
  }
}
export default CollapsibleInfo
