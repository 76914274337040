import React, { Component, Fragment } from "react"
import moment from "moment"
import Img from "gatsby-image"
import Media from "react-media"
import Resizer from "react-image-file-resizer"
import { useTranslation, withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"

import pdfIcon from "../../assets/images/pdf.png"
import classNames from "classnames"
import styles from "./dropzone.module.scss"

const DOCUMENT_UPLOAD_TYPE = "conforme"
const MINIMUM_SIZE_TO_COMPRESS = 1048576

const DivThumbNail = ({ imgData, indexImg, handleDeleteImage }) => {
  const { t } = useTranslation()
  const isPDF =
    imgData.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  return (
    <div className="column is-2-tablet is-3-desktop is-6-mobile mx-0 pl-0">
      <div className={classNames(styles.thumbnailDiv, "p-1")}>
        <center>
          <figure className={classNames("image is-96x96", styles.img)}>
            <img src={isPDF ? pdfIcon : imgData} alt="attachment" />
          </figure>
          <button
            className="button is-danger is-outlined is-small"
            onClick={handleDeleteImage(indexImg)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faTrash} />
            </span>
            <span>{t("Remove")}</span>
          </button>
        </center>
      </div>
    </div>
  )
}

class DropzoneBody extends Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
    this.state = {
      haveFileUploaded: !!this.props.dropzoneFiles
        ? !!this.props.dropzoneFiles.length
        : false,
      filesUploaded: this.props.dropzoneFiles,
      limitedNumberOfFiles: this.props.limit ? parseInt(this.props.limit) : -1,
      maxFileSizeInBytes: this.props.maxFileSizeInBytes
        ? this.props.maxFileSizeInBytes
        : -1,
      isLimit: false,
      showFileSizeError: false,
    }
  }

  handleFileChooser = () => {
    this.fileInput.current.click()
  }

  handleIfFileIsAlreadyUploaded = fileInputDataURL => {
    let fileFound = this.state.filesUploaded.filter(fileItem => {
      return fileItem === fileInputDataURL
    })
    return !!fileFound.length
  }

  handlePushFileToState = fileByte => {
    this.setState({
      haveFileUploaded: true,
      filesUploaded: fileByte,
    })
  }

  handleDeleteImage = imageIndex => event => {
    event.preventDefault()
    this.setState(
      previousState => {
        let tempUploadedFiles = previousState.filesUploaded
        tempUploadedFiles.splice(imageIndex, 1)
        return {
          filesUploaded: tempUploadedFiles,
          isLimit: false,
          haveFileUploaded: tempUploadedFiles.length > 0 ? true : false,
        }
      },
      () => {
        if (this.props.handleContentMap) {
          this.props.handleContentMap(this.state.filesUploaded)
        }
      }
    )
  }

  handleCheckFileSize = file => {
    return file.size < this.state.maxFileSizeInBytes
  }

  generateFileName = format => {
    const fileExtension = format.split("/")[1]
    // return `${type}_${moment().format("YYYYMMDD_HHmmss")}.${fileExtension}`
    return `rx_${moment().format("YYYYMMDD_HHmmss")}${
      fileExtension === "pdf" ? ".pdf" : ""
    }`
  }

  handleSaveFiles = (uploadedFiles, tempArrayFile) => {
    if (
      this.state.limitedNumberOfFiles > -1 &&
      this.state.filesUploaded.length < this.state.limitedNumberOfFiles
    ) {
      tempArrayFile.forEach((file, index) => {
        if (this.handleIfFileIsAlreadyUploaded(file)) {
          tempArrayFile.splice(index, 1)
          if (this.props.callbackFileDuplicateMessage)
            this.props.callbackFileDuplicateMessage(uploadedFiles[index].name)
        }
      })
    }
    this.setState(
      previousState => {
        return {
          haveFileUploaded: true,
          filesUploaded: previousState.filesUploaded.concat(tempArrayFile),
        }
      },
      () => {
        if (this.props.handleContentMap) {
          this.props.handleContentMap(this.state.filesUploaded)
        }
      }
    )
  }

  handleFileRead = async event => {
    const eventFiles = event.target.files
    const tempArrayFile = []
    for (let i = 0; i < eventFiles.length; i++) {
      let file = eventFiles[i]
      let reader = new FileReader()
      if (
        file.type === "application/pdf" ||
        file.size < MINIMUM_SIZE_TO_COMPRESS
      ) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          const newFile = {
            path: reader.result,
            buffer: reader.result,
            name: this.generateFileName(file.type),
            filename: this.generateFileName(file.type),
            oldname: file.name,
          }
          tempArrayFile.push(newFile)
          this.handleSaveFiles(eventFiles, tempArrayFile)
        }
      } else {
        await Resizer.imageFileResizer(
          file,
          1000,
          1000,
          "JPEG",
          100,
          0,
          uri => {
            const newFile = {
              path: uri,
              buffer: uri,
              name: this.generateFileName(file.type),
              filename: this.generateFileName(file.type),
              oldname: file.name,
            }
            tempArrayFile.push(newFile)
            this.handleSaveFiles(eventFiles, tempArrayFile)
          },
          "base64"
        )
      }
    }
  }

  render() {
    const { t } = this.props
    const dropzoneIcon =
      this.props.uploadType === DOCUMENT_UPLOAD_TYPE
        ? this.props.data.documentLogo.childImageSharp.fluid
        : this.props.data.cameraLogo.childImageSharp.fluid
    return (
      <div className={classNames("panel", styles.divDropzone)}>
        <input
          type="file"
          ref={this.fileInput}
          className={classNames(styles.isDisplayNone)}
          onChange={this.handleFileRead}
          multiple
          accept="image/*,.pdf"
        />
        {!!this.state.showFileSizeError && (
          <div className="notification is-danger py-1 mb-0">
            Cannot upload file with size greater than{" "}
            {Math.round(parseInt(this.state.maxFileSizeInBytes) / 1000000)}MB.
            Please upload a smaller file or change your camera settings to
            Standard quality.
          </div>
        )}
        {!this.state.haveFileUploaded ? (
          <div
            className={classNames("is-flex py-4", styles.innerBorder)}
            onClick={this.handleFileChooser}
          >
            <div className={classNames(styles.divLogo)}>
              <Img fluid={dropzoneIcon} />
            </div>
            <p>{t(`Upload ${this.props.uploadType || "your prescription"}`)}</p>

            <span className="help">Maximum file size: 5 MB</span>
          </div>
        ) : (
          <Fragment>
            <div
              className={classNames(
                "columns is-multiline is-mobile",
                styles.dropzoneHeight
              )}
            >
              {this.state.filesUploaded.map((file, index) => {
                return (
                  <DivThumbNail
                    key={index}
                    imgData={file.buffer}
                    indexImg={index}
                    handleDeleteImage={this.handleDeleteImage}
                  />
                )
              })}
            </div>

            {this.state.filesUploaded.length <
              this.state.limitedNumberOfFiles && (
              <div
                className={classNames(styles.plusFileButton)}
                onClick={this.handleFileChooser}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                <span>{t("Add files or Take a picture")}</span>
              </div>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default withTranslation()(DropzoneBody)
